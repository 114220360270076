import React from "react";
import "./ClassificationBubble.css";

function ClassificationBubble({
  limited_quantites,
  regulated,
  regulated_exception,
}) {
  let bubbleText = "";
  let bubbleColor = "";
  let textColor = "";

  // Determine the state based on the props
  if (limited_quantites > 0) {
    bubbleText = "Limited quantity";
    bubbleColor = "#FDB72E";
    textColor = "black";
  } else if (
    regulated === "NOT REGULATED" ||
    regulated === null ||
    (regulated === "" && regulated_exception === "")
  ) {
    bubbleText = "Not regulated";
    bubbleColor = "#40B4E5";
    textColor = "white";
  } else if (regulated_exception !== "") {
    bubbleText = "Exceptions apply";
    bubbleColor = "#C4EA92";
    textColor = "black";
  } else {
    bubbleText = "Fully regulated";
    bubbleColor = "#F36E24";
    textColor = "white";
  }

  return (
    <div
      className="classification-bubble"
      style={{ backgroundColor: bubbleColor, border: "none" }}
    >
      <p style={{ color: textColor, fontSize: "14px" }}>{bubbleText}</p>
    </div>
  );
}

export default ClassificationBubble;
