import "./PieChart.css";

const PieChart = ({ data }) => {
  // Calculate the total value of all slices
  const total = data.reduce((acc, entry) => acc + entry.value, 0);

  // Create the conic-gradient based on the data
  let gradientPieces = [];
  let accumulatedValue = 0;

  data.forEach((slice) => {
    const start = (accumulatedValue / total) * 100;
    const end = ((accumulatedValue + slice.value) / total) * 100;
    gradientPieces.push(`${slice.color} ${start}% ${end}%`);
    accumulatedValue += slice.value;
  });

  const pieChartStyle = {
    background: `conic-gradient(${gradientPieces.join(", ")})`,
  };

  return <div className="pie-chart" style={pieChartStyle}></div>;
};

export default PieChart;
