import "./Footer.css";

function Footer({ productsView }) {
  return (
    <div className="footer">
      <div className="footer-container">
        <p className="footer-text">2023 SmarterX. All right reserved.</p>
        <a
          className="footer-url"
          href="https://www.smarterx.com/legal/privacy-policy"
        >
          Privacy Policy
        </a>
        <a
          className="footer-url"
          href="https://www.smarterx.com/legal/terms-of-use"
        >
          Terms of Use
        </a>
      </div>
    </div>
  );
}

export default Footer;
