import PieChart from "../../../components/PieChart/PieChart.js";

import "./ResultsDashboard.css";

function ResultsDashboard({
  totalProducts,
  totalStateRegulated,
  setShowDashboard,
  setShowProductInfo,
}) {
  return (
    <div className="dashboard-container">
      <div className="dashboard-left">
        <div className="product-count-container">
          <p className="dashboard-left-header">WHAT WE FOUND</p>
          <p className="total-products-text">{totalProducts}</p>
          <p className="dashboard-right-text">products classified so far in</p>
          <div className="timer-card">
            <img
              style={{ width: 20 }}
              src={`${process.env.PUBLIC_URL}/timer-icon.svg`}
              alt="timer"
            />
            <p className="timer-text">30 SEC</p>
          </div>
          <p style={{ fontWeight: "600", textAlign: "left" }}>
            Don't believe it?
          </p>
          <p style={{ textAlign: "left" }}>
            Find out more about the product classifications in the detailed
            view.
          </p>
          <button className="cta" style={{ width: "100%" }}>
            <div
              onClick={() => {
                setShowProductInfo(true);
                setShowDashboard(false);
                document.body.style.overflow = "auto";
                window.dataLayer?.push({
                  event: 'show_results'
                })
              }}
              style={{
                display: "flex",
                flexDirection: "row",
                gap: "8px",
                justifyContent: "center",
                alignItems: "center",
                width: "100%",
              }}
            >
              <div>Show me</div>
              <img
                style={{ width: 20 }}
                src={`${process.env.PUBLIC_URL}/arrow-icon.svg`}
                alt="arrow"
              />
            </div>
          </button>
        </div>
        <div className="regulation-count-container">
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "20px",
            }}
          >
            <h4 style={{ fontWeight: "600", margin: 0 }}>Regulated products</h4>
            <p style={{ color: "#818283" }}>
              Did you know that not all of the products we looked at need to be
              classified? See the breakdown of our findings below.
            </p>
          </div>
          <div
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "column",
              justifyContents: "center",
              alignItems: "center",
              gap: "40px",
            }}
          >
            <PieChart
              data={[
                {
                  value:
                    parseInt(totalProducts.replace(/,/g, "")) -
                    parseInt(totalStateRegulated.replace(/,/g, "")),
                  color: "#40B4E5",
                },
                {
                  value: parseInt(totalProducts.replace(/,/g, "")),
                  color: "#FDB72E",
                },
              ]}
            />
            <div className="pie-chart-legend">
              <div className="pie-chart-legend-card">
                <p
                  className="pie-chart-legend-number"
                  style={{ color: "#FDB72E" }}
                >
                  {totalStateRegulated}
                </p>
                <p>regulated products</p>
              </div>
              <div className="pie-chart-legend-card">
                <p
                  className="pie-chart-legend-number"
                  style={{ color: "#40B4E5" }}
                >
                  {(
                    parseInt(totalProducts.replace(/,/g, "")) -
                    parseInt(totalStateRegulated.replace(/,/g, ""))
                  ).toLocaleString()}
                </p>
                <p>unregulated products</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="dashboard-right">
        <p className="dashboard-right-header">
          Wondering how we classified these products so quickly?
        </p>
        <p className="dashboard-right-text">
          SmarterX has a massive database of detailed supplier-attested
          regulatory, chemical and ingredient data on millions of products.
        </p>
        <p className="dashboard-right-text">
          We use this data to classify products with a high level of accuracy.
        </p>
        <img
          style={{ width: "80%", height: "auto" }}
          src={`${process.env.PUBLIC_URL}/tube-3.png`}
          alt="Logo"
        />
      </div>
    </div>
  );
}

export default ResultsDashboard;
