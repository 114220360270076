import { createContext } from "react";

// Create a context with an initial value
const RetailerSelection = createContext({
  selectedRetailer: "",
  setSelectedRetailer: () => {},
  selectionHistory: [],
  setSelectionHistory: () => {},
  emailInput: "",
  setEmailInput: () => {},
});

export { RetailerSelection };
