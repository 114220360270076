import { useContext, useState, useEffect, useRef } from "react";
import { CurrentView } from "../../context/CurrentView.js";
import { RetailerSelection } from "../../context/RetailerSelection.js";
import { ModalManagement } from "../../context/ModalManagement.js";

import ResultsDashboard from "./ResultsDashboard/ResultsDashboard.js";
import ProductInfo from "./ProductInfo/ProductInfo.js";

import "./ResultsView.css";

function ResultsView({
  products,
  totalProducts,
  totalStateRegulated,
  isMobile,
}) {
  const { currentView } = useContext(CurrentView);
  const { selectedRetailer } = useContext(RetailerSelection);
  const { setPricingModalOpen } = useContext(ModalManagement);

  const [showDashboard, setShowDashboard] = useState(true);
  const [showProductInfo, setShowProductInfo] = useState(false);

  const hasPricingModalAppeared = useRef(false);
  useEffect(() => {
    if (
      showDashboard &&
      currentView === "ResultsPage" &&
      !hasPricingModalAppeared.current
    ) {
      setTimeout(() => {
        if (showDashboard) {
          setPricingModalOpen(true);
          hasPricingModalAppeared.current = true;
        }
      }, 6000);
    }
  }, [showDashboard, currentView, setPricingModalOpen]);

  if (currentView !== "ResultsPage") {
    return;
  } else {
    return (
      <div className="results-view-container">
        <div className="content-header">
          <h2>{selectedRetailer.name} products</h2>
          <div id="tabs">
            <div
              id="tab"
              className={showDashboard ? "tab-selected" : "tab-unselected"}
              onClick={() => {
                setShowDashboard(true);
                setShowProductInfo(false);
                // disable scroll on body
                if (!isMobile) {
                  document.body.style.overflow = "hidden";
                }
              }}
            >
              <p style={{ color: showDashboard ? "#000" : "#818283" }}>
                Overview
              </p>
            </div>
            <div
              id="tab"
              className={!showDashboard ? "tab-selected" : "tab-unselected"}
              onClick={() => {
                setShowProductInfo(true);
                setShowDashboard(false);
                if (!isMobile) {
                  document.body.style.overflow = "auto";
                }
              }}
            >
              <p style={{ color: !showDashboard ? "#000" : "#818283" }}>
                Products
              </p>
            </div>
          </div>
        </div>
        {showDashboard && (
          <ResultsDashboard
            totalProducts={totalProducts}
            totalStateRegulated={totalStateRegulated}
            setShowDashboard={setShowDashboard}
            setShowProductInfo={setShowProductInfo}
          />
        )}
        {showProductInfo && (
          <ProductInfo products={products} isMobile={isMobile} />
        )}
      </div>
    );
  }
}

export default ResultsView;
