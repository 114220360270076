// Modal.js

import React from 'react';
import './CtaModal.css';

function CtaModal({ show, onClose, onClick }) {
    if (!show) {
        return null;
    }

    return (
        <div className="modal-overlay">
            <div className="cta-modal-content">
                <h1 style={{textAlign: 'center', lineHeight: 1.2, marginBottom: 20, maxWidth: 500}}>With the full power of SmarterX, these aren't just classifications.</h1>
                <h3 style={{padding: 0, fontSize: 16}}>Unlock the capability to do more with your product data:</h3>
                <div className='check-item'>
                    <h3 style={{padding: 0, fontSize: 16}}>Download</h3>
                    <img src={`${process.env.PUBLIC_URL}/check-line.svg`} alt="check" />
                </div>
                <div className='check-item'>
                    <h3 style={{padding: 0, fontSize: 16}}>Share via API</h3>
                    <img src={`${process.env.PUBLIC_URL}/check-line.svg`} alt="check" />
                </div>
                <div className='check-item'>
                    <h3 style={{padding: 0, fontSize: 16}}>Monitor for & receive updates</h3>
                    <img src={`${process.env.PUBLIC_URL}/check-line.svg`} alt="check" />
                </div>
                <div className='check-item'>
                    <h3 style={{padding: 0, fontSize: 16}}>Compare to marketing claims</h3>
                    <img src={`${process.env.PUBLIC_URL}/check-line.svg`} alt="check" />
                </div>
                <h3 style={{padding: 0, fontSize: 16}}>And more...</h3>
                <h2 style={{fontWeight: 400, fontSize: 24, marginTop: 20, marginBottom: 20}}>Want more from your product data?</h2>
                <button onClick={onClick} className='cta'>Let's talk</button>
                <button onClick={onClose} className="close-button-bottom">Close</button>
            </div>
        </div>
    );
}

export default CtaModal;
