// TableRow.js

import React from "react";
import "./TableRow.css";

function TableRow({ product, onView, skeleton = false, isMobile = false }) {
  if (!skeleton) {
    if (!isMobile) {
      return (
        <div className="table-row">
          <td style={{ width: "100%" }}>{product.name}</td>
          <td style={{ width: "60%" }}>{product.category}</td>
          <td style={{ width: "100%" }}>{product.brand}</td>
          <td style={{ minWidth: 200 }}>
            <div className="classification-icons">
              {product.ground_regulated === "NOT REGULATED" ||
              product.ground_regulated === "" ||
              product.ground_regulated === null ? (
                <img
                  style={{ width: 20 }}
                  src={`${process.env.PUBLIC_URL}/check.svg`}
                  alt="check"
                />
              ) : (
                <img
                  style={{ width: 20 }}
                  src={`${process.env.PUBLIC_URL}/document.svg`}
                  alt="document"
                />
              )}
              {product.air_regulated === "NOT REGULATED" ||
              product.air_regulated === "" ||
              product.air_regulated === null ? (
                <img
                  style={{ width: 20 }}
                  src={`${process.env.PUBLIC_URL}/check.svg`}
                  alt="check"
                />
              ) : (
                <img
                  style={{ width: 20 }}
                  src={`${process.env.PUBLIC_URL}/document.svg`}
                  alt="document"
                />
              )}
              {product.sea_regulated === "NOT REGULATED" ||
              product.sea_regulated === "" ||
              product.sea_regulated === null ? (
                <img
                  style={{ width: 20 }}
                  src={`${process.env.PUBLIC_URL}/check.svg`}
                  alt="check"
                />
              ) : (
                <img
                  style={{ width: 20 }}
                  src={`${process.env.PUBLIC_URL}/document.svg`}
                  alt="document"
                />
              )}
              {(product.rcra_codes.length || product.state_codes.length) ===
              0 ? (
                <img
                  style={{ width: 20 }}
                  src={`${process.env.PUBLIC_URL}/check.svg`}
                  alt="check"
                />
              ) : (
                <img
                  style={{ width: 20, fill: "#F36E24" }}
                  src={`${process.env.PUBLIC_URL}/delete-bin-orange.svg`}
                  alt="document"
                />
              )}
            </div>
          </td>
          <button
            className="view-classification-button"
            style={{ width: "100%", maxWidth: 180, minWidth: 80 }}
            onClick={onView}
          >
            View classifications
          </button>
        </div>
      );
    } else {
      return (
        <div className="mobile-card">
          <p
            className="table-row-text"
            style={{ textDecorationLine: "underline" }}
          >
            {product.name}
          </p>
          <p className="table-row-text">{product.product_type}</p>
          <p className="table-row-text">{product.brand}</p>
          <div className="classification-icons-mobile">
            <div className="mobile-icons">
              <img
                style={{ width: 20 }}
                src={`${process.env.PUBLIC_URL}/truck-line.svg`}
                alt="truck"
              />
              {product.ground_regulated === "NOT REGULATED" ||
              product.ground_regulated === "" ? (
                <img
                  style={{ width: 20 }}
                  src={`${process.env.PUBLIC_URL}/check.svg`}
                  alt="check"
                />
              ) : (
                <img
                  style={{ width: 20 }}
                  src={`${process.env.PUBLIC_URL}/document.svg`}
                  alt="document"
                />
              )}
            </div>
            <div className="mobile-icons">
              <img
                style={{ width: 20 }}
                src={`${process.env.PUBLIC_URL}/plane-line.svg`}
                alt="plane"
              />
              {product.air_regulated === "NOT REGULATED" ||
              product.air_regulated === "" ? (
                <img
                  style={{ width: 20 }}
                  src={`${process.env.PUBLIC_URL}/check.svg`}
                  alt="check"
                />
              ) : (
                <img
                  style={{ width: 20 }}
                  src={`${process.env.PUBLIC_URL}/document.svg`}
                  alt="document"
                />
              )}
            </div>
            <div className="mobile-icons">
              <img
                style={{ width: 20 }}
                src={`${process.env.PUBLIC_URL}/ship-line.svg`}
                alt="ship"
              />
              {product.sea_regulated === "NOT REGULATED" ||
              product.sea_regulated === "" ? (
                <img
                  style={{ width: 20 }}
                  src={`${process.env.PUBLIC_URL}/check.svg`}
                  alt="check"
                />
              ) : (
                <img
                  style={{ width: 20 }}
                  src={`${process.env.PUBLIC_URL}/document.svg`}
                  alt="document"
                />
              )}
            </div>
            <div className="mobile-icons">
              <img
                style={{ width: 20 }}
                src={`${process.env.PUBLIC_URL}/delete-bin-fill.svg`}
                alt="trash"
              />
              {product.rcra_codes.length === 0 ||
              product.state_codes.length === 0 ? (
                <img
                  style={{ width: 20 }}
                  src={`${process.env.PUBLIC_URL}/check.svg`}
                  alt="check"
                />
              ) : (
                <img
                  style={{ width: 20, fill: "#F36E24" }}
                  src={`${process.env.PUBLIC_URL}/delete-bin-orange.svg`}
                  alt="document"
                />
              )}
            </div>
          </div>
          <button
            className="view-classification-button"
            style={{ width: "100%" }}
            onClick={onView}
          >
            View classifications
          </button>
        </div>
      );
    }
  } else {
    if (!isMobile) {
      return (
        <div className="table-row">
          <td style={{ width: "100%" }}>
            <div className="table-row-skeleton" />
          </td>
          <td style={{ width: "60%" }}>
            <div className="table-row-skeleton" />
          </td>
          <td style={{ width: "100%" }}>
            <div className="table-row-skeleton" />
          </td>
          <td style={{ minWidth: 200 }}>
            <div className="classification-icons">
              <div className="dash" />
              <div className="dash" />
              <div className="dash" />
              <div className="dash" />
            </div>
          </td>
          <button
            className="secondary"
            style={{
              width: "100%",
              maxWidth: 180,
              minWidth: 80,
              backgroundColor: "#d9dadb",
              color: "#818283",
            }}
            onClick={onView}
          >
            View
          </button>
        </div>
      );
    } else {
      return (
        <div className="table-row">
          <td style={{ width: "100%" }}>
            <div className="table-row-skeleton" />
          </td>
          <td style={{ width: "60%" }}>
            <div className="table-row-skeleton" />
          </td>
          <td style={{ width: "100%" }}>
            <div className="table-row-skeleton" />
          </td>
          <td style={{ minWidth: 0 }}>
            <div className="classification-icons">
              <div className="dash" />
              <div className="dash" />
              <div className="dash" />
              <div className="dash" />
            </div>
          </td>
          <button
            className="secondary"
            style={{
              width: "100%",
              maxWidth: 180,
              minWidth: 80,
              backgroundColor: "#d9dadb",
              color: "#818283",
            }}
            onClick={onView}
          >
            View
          </button>
        </div>
      );
    }
  }
}

export default TableRow;
