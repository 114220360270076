import { createContext } from "react";

const ModalManagement = createContext({
  isItemModalOpen: false,
  setItemModalOpen: () => {},
  activeProduct: null,
  setActiveProduct: () => {},
  isCtaModalOpen: false,
  setCtaModalOpen: () => {},
  isCalendlyModalOpen: false,
  setCalendlyModalOpen: () => {},
  isPricingModalOpen: false,
  setPricingModalOpen: () => {},
});

export { ModalManagement };
