import { useContext } from "react";
import { CurrentView } from "../../context/CurrentView.js";
import { ModalManagement } from "../../context/ModalManagement.js";
import "./Header.css";

function Header({ restartDemo, isMobile }) {
  const { currentView } = useContext(CurrentView);
  const { setCalendlyModalOpen } = useContext(ModalManagement);

  if (!isMobile) {
    return (
      <>
        <div className="logo-container">
          <img
            style={{ width: 150, marginTop: "15px" }}
            src={`${process.env.PUBLIC_URL}/SmarterXLogo.svg`}
            alt="Logo"
          />
          <div className="demo-logo-container">
            <img
              src={`${process.env.PUBLIC_URL}/demo-icon.svg`}
              alt="Demo Logo"
            />
            <p className="demo-logo-text">Demo mode</p>
          </div>
        </div>
        {currentView === "ResultsPage" && (
          <div
            style={{
              display: "flex",
              gap: "24px",
              alignItems: "center",
            }}
          >
            <a
              className="return-link"
              style={{ marginRight: "20px" }}
              href="https://www.smarterx.com/"
            >
              Back to SmarterX.com
            </a>
            <button className="button-outline" onClick={restartDemo}>
              <img
                src={`${process.env.PUBLIC_URL}/restart-icon.svg`}
                alt="Restart Icon"
              />
              <span style={{ color: "#77BC1F" }}>Try another retailer</span>
            </button>
            <button className="cta" onClick={() => setCalendlyModalOpen(true)}>
              <span className="button-text">GET ACCESS</span>
            </button>
          </div>
        )}
      </>
    );
  } else {
    return (
      <>
        <div className="header-container-mobile">
          <div className="header-row-mobile">
            <div className="logo-container">
              <img
                style={{ width: 122, marginTop: "15px" }}
                src={`${process.env.PUBLIC_URL}/SmarterXLogo.svg`}
                alt="Logo"
              />
              <div className="demo-logo-container">
                <img
                  src={`${process.env.PUBLIC_URL}/demo-icon.svg`}
                  alt="Demo Logo"
                />
                <p className="demo-logo-text">Demo mode</p>
              </div>
            </div>
            <a
              className="return-link"
              style={{
                height: "100%",
                display: "flex",
                alignItems: "center",
              }}
              href="https://www.smarterx.com/"
            >
              Back to SmarterX.com
            </a>
          </div>
          {currentView === "ResultsPage" && (
            <div className="header-row-mobile">
              <button
                className="button-outline"
                style={{ padding: "8px" }}
                onClick={restartDemo}
              >
                <img
                  src={`${process.env.PUBLIC_URL}/restart-icon.svg`}
                  style={{ width: "18px" }}
                  alt="Restart Icon"
                />
                <span style={{ color: "#77BC1F" }}>Try another retailer</span>
              </button>
              <button
                className="cta"
                style={{ padding: "10px 20px" }}
                onClick={() => {
                    setCalendlyModalOpen(true)
                    window.dataLayer?.push({
                      event: "open_scheduler",
                      event_location: "Navbar"
                    })
                  }
                }
              >
                <span className="button-text" style={{ fontSize: "18px" }}>
                  GET ACCESS
                </span>
              </button>
            </div>
          )}
        </div>
      </>
    );
  }
}

export default Header;
