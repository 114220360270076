import React, { useState, useEffect } from "react";

import Header from "./components/Header/Header.js";
import LandingView from "./views/LandingView/LandingView.js";
import LoadingView from "./views/LoadingView/LoadingView.js";
import ResultsView from "./views/ResultsView/ResultsView.js";
import Footer from "./components/Footer/Footer";

import ItemModal from "./components/ItemModal/ItemModal";
import CtaModal from "./components/CtaModal/CtaModal";
import CalendlyModal from "./components/CalendlyModal/CalendlyModal";
import CalendlyEmbed from "./components/CalendlyEmbed/CalendlyEmbed";
import PricingModal from "./components/PricingModal/PricingModal";

import { CurrentView } from "./context/CurrentView.js";
import { RetailerSelection } from "./context/RetailerSelection.js";
import { ModalManagement } from "./context/ModalManagement.js";

import "./App.css";
import "./styles/styles.css";
import "./styles/layout.css";

function App() {
  const [currentView, setCurrentView] = useState("LandingPage");
  const [selectedRetailer, setSelectedRetailer] = useState(null);
  const [emailInput, setEmailInput] = useState("");
  const [isItemModalOpen, setItemModalOpen] = useState(false);
  const [activeProduct, setActiveProduct] = useState(null);
  const [isCtaModalOpen, setCtaModalOpen] = useState(false);
  const [isCalendlyModalOpen, setCalendlyModalOpen] = useState(false);
  const [isPricingModalOpen, setPricingModalOpen] = useState(false);

  const [retailers, setRetailers] = useState([]);
  const [products, setProducts] = useState([]);

  // const [apiResponse, setApiResponse] = useState(null);
  // const [searchTerm, setSearchTerm] = useState("");

  const totalProducts = selectedRetailer
    ? selectedRetailer.total_products.toLocaleString()
    : 0;
  const totalStateRegulated = selectedRetailer
    ? selectedRetailer.products_state_regulated.toLocaleString()
    : 0;

  const handleSubmit = async () => {
    try {
      const pageSize = 100;
      const response = await fetch(
        `https://smarterx-demo-app.replit.app/get_retailer_products?name=${encodeURIComponent(
          selectedRetailer.name
        )}&page_size=${pageSize}`
      );
      const data = await response.json();
      setProducts(data);
    } catch (error) {
      console.error("Error fetching products:", error);
    }

    // save email to database
    fetch("https://smarterx-demo-app.replit.app/user_access", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        user_email: emailInput,
        retailer_name: selectedRetailer.name,
      }),
    })
      .then((response) => response.json()) // assuming server responds with json
      .then((data) => {
        //GA4 Event - demo_email_submission
        window.dataLayer?.push({
          event: "demo_email_submit",
          retailer_name: selectedRetailer.name,
        });
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  useEffect(() => {
    fetch("https://smarterx-demo-app.replit.app/get_all_retailers")
      .then((response) => response.json())
      .then((data) => {
        setRetailers(data);
      })
      .catch((error) => console.error("Error fetching retailers:", error));
  }, []);

  // const filteredProducts =
  //   apiResponse && apiResponse.products
  //     ? apiResponse.products.filter((product) => {
  //         const productData = [
  //           product.product.upc,
  //           product.product.productName,
  //           ...product.product.productCategories,
  //           product.product.brand,
  //         ]
  //           .join(" ")
  //           .toLowerCase();

  //         return productData.includes(searchTerm.toLowerCase());
  //       })
  //     : [];

  // mobile responsive variables
  const [windowWidth, setWindowWidth] = React.useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => setWindowWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);

    // Cleanup event listener on unmount
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const isMobile = windowWidth < 768;

  // restart function
  const restartDemo = () => {
    setCurrentView("LandingPage");
    setProducts([]);
    setEmailInput("");
    setSelectedRetailer(null);
  };

  return (
    <CurrentView.Provider value={{ currentView, setCurrentView }}>
      <RetailerSelection.Provider
        value={{
          selectedRetailer,
          setSelectedRetailer,
          emailInput,
          setEmailInput,
        }}
      >
        <ModalManagement.Provider
          value={{
            isItemModalOpen,
            setItemModalOpen,
            activeProduct,
            setActiveProduct,
            isCtaModalOpen,
            setCtaModalOpen,
            isCalendlyModalOpen,
            setCalendlyModalOpen,
            isPricingModalOpen,
            setPricingModalOpen,
          }}
        >
          <div className="App">
            <header>
              <Header restartDemo={restartDemo} isMobile={isMobile} />
            </header>
            <main>
              {products.length === 0 && (
                <LandingView
                  retailers={retailers}
                  handleSubmit={handleSubmit}
                  isMobile={isMobile}
                />
              )}
              <LoadingView isMobile={isMobile} />
              <ResultsView
                products={products}
                totalProducts={totalProducts}
                totalStateRegulated={totalStateRegulated}
                isMobile={isMobile}
              />
            </main>
            {currentView !== "ResultsPage" && <Footer />}

            <ItemModal
              show={isItemModalOpen}
              onClose={() => setItemModalOpen(false)}
              product={activeProduct}
            />
            <CtaModal
              show={isCtaModalOpen}
              onClick={() => {
                setCalendlyModalOpen(true);
                setCtaModalOpen(false);
              }}
              onClose={() => setCtaModalOpen(false)}
            />
            <CalendlyModal
              isOpen={isCalendlyModalOpen}
              onClose={() => setCalendlyModalOpen(false)}
            >
              <CalendlyEmbed />
            </CalendlyModal>
          </div>
        </ModalManagement.Provider>
      </RetailerSelection.Provider>
    </CurrentView.Provider>
  );
}

export default App;
