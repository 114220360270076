import { useContext, useEffect, useState } from "react";
import { CurrentView } from "../../context/CurrentView.js";
import { RetailerSelection } from "../../context/RetailerSelection.js";

import "./LoadingView.css";

function LoadingView({ isMobile }) {
  const { currentView, setCurrentView } = useContext(CurrentView);
  const { selectedRetailer } = useContext(RetailerSelection);
  const [loadingStep, setLoadingStep] = useState(1);

  useEffect(() => {
    if (currentView === "LoadingPage" && loadingStep < 5) {
      setTimeout(() => {
        setLoadingStep(loadingStep + 1);
      }, 4000);
    }
  }, [loadingStep, currentView]);

  if (currentView !== "LoadingPage") {
    return;
  } else {
    return (
      <div className="loading-container">
        <div id="loading-bar-background">
          <div
            id="loading-bar"
            style={{
              width: `${loadingStep * 20}%`,
              transition: "width 2s ease-in-out",
            }}
          ></div>
        </div>
        <div className="step-container">
          <p style={{ color: "#818283", fontSize: "14px" }}>
            {loadingStep === 1 && "checking product names..."}
            {loadingStep === 2 && "scanning for regulatory flags..."}
            {loadingStep === 3 && "searching for available documentation..."}
            {loadingStep === 4 && "checking state-specific codes..."}
            {loadingStep === 5 && "Analysis complete"}
          </p>
          {loadingStep === 1 ? (
            <h3
              style={{
                marginTop: "50px",
                marginBottom: "50px",
                maxWidth: "600px",
              }}
            >
              {"Got it. We've already started checking "}
              <strong>{selectedRetailer.name + ".com"}</strong>
              {" for regulated products."}
            </h3>
          ) : (
            <div className="kpi-container">
              <div className="kpi-group">
                <div className="kpi">
                  <h1>
                    {loadingStep < 3 ? (
                      <img
                        src={`${process.env.PUBLIC_URL}/checkbox-grey.svg`}
                        alt=""
                      />
                    ) : (
                      <img src={`${process.env.PUBLIC_URL}/check.svg`} alt="" />
                    )}
                  </h1>
                  <p
                    style={{
                      textAlign: "center",
                      fontWeight: "600",
                      fontSize: "14px",
                    }}
                  >
                    total products
                  </p>
                </div>
                <div className="kpi">
                  <h1>
                    {loadingStep < 3 ? (
                      <img
                        src={`${process.env.PUBLIC_URL}/checkbox-grey.svg`}
                        alt=""
                      />
                    ) : (
                      <img src={`${process.env.PUBLIC_URL}/check.svg`} alt="" />
                    )}
                  </h1>
                  <p
                    style={{
                      textAlign: "center",
                      fontWeight: "600",
                      fontSize: "14px",
                    }}
                  >
                    products regulated for transportation
                  </p>
                </div>
              </div>
              <div className="kpi-group">
                <div className="kpi">
                  <h1>
                    {loadingStep < 4 ? (
                      <img
                        src={`${process.env.PUBLIC_URL}/checkbox-grey.svg`}
                        alt=""
                      />
                    ) : (
                      <img src={`${process.env.PUBLIC_URL}/check.svg`} alt="" />
                    )}
                  </h1>
                  <p
                    style={{
                      textAlign: "center",
                      fontWeight: "600",
                      fontSize: "14px",
                    }}
                  >
                    federal waste classifications
                  </p>
                </div>
                <div className="kpi">
                  <h1>
                    {loadingStep < 5 ? (
                      <img
                        src={`${process.env.PUBLIC_URL}/checkbox-grey.svg`}
                        alt=""
                      />
                    ) : (
                      <img src={`${process.env.PUBLIC_URL}/check.svg`} alt="" />
                    )}
                  </h1>
                  <p
                    style={{
                      textAlign: "center",
                      fontWeight: "600",
                      fontSize: "14px",
                    }}
                  >
                    products regulated under state-specific codes
                  </p>
                </div>
              </div>
            </div>
          )}
          <div className="sorty-container">
            {loadingStep !== 5 && (
              <>
                <p style={{ width: "250px", marginBottom: "6px" }}>
                  {loadingStep === 1
                    ? "By the way, my name is Sorty! I’m an AI-powered engine here to run classifications."
                    : "Still working, but I wanted to show you stuff you probably didn’t know about "}{" "}
                  <strong>{selectedRetailer.name}</strong> {" products!"}
                </p>
                <img
                  src={`${process.env.PUBLIC_URL}/callout.svg`}
                  alt="callout"
                />
              </>
            )}
            {loadingStep === 1 && (
              <img
                style={{ width: 250, marginTop: 20 }}
                src={`${process.env.PUBLIC_URL}/sorty1.png`}
                alt="sorty"
              />
            )}
            {loadingStep > 1 && loadingStep < 5 && (
              <img
                style={{ width: 250, marginTop: 20 }}
                src={`${process.env.PUBLIC_URL}/sorty2.png`}
                alt="sorty"
              />
            )}
          </div>
          {loadingStep === 5 && (
            <div className="sorty-container">
              <img
                style={{
                  width: 100,
                  alignSelf: "center",
                  marginBottom: 30,
                }}
                src={`${process.env.PUBLIC_URL}/sorty3.png`}
                alt="sorty"
              />
              <h2
                style={{
                  width: 500,
                  textAlign: "center",
                }}
              >
                Aaaand... done!
              </h2>
              <h4
                style={{
                  width: 500,
                  textAlign: "center",
                }}
              >
                {"Ready to view "}
                <strong>{selectedRetailer.name}</strong>
                {"'s classified products?"}
              </h4>
              <button
                style={{ width: 250 }}
                onClick={() => {
                  setCurrentView("ResultsPage");
                  setLoadingStep(1);
                }}
              >
                Let's go!
              </button>
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default LoadingView;
