import React, { useState, useEffect } from "react";
import "./DropDown.css";

function Dropdown({ options, label, onSelect, defaultValue }) {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState(null);
  const [borderRadius, setBorderRadius] = useState("4px");

  useEffect(() => {
    if (defaultValue) {
      const defaultOption = options.find(
        (option) => option.label === defaultValue
      );
      setSelectedOption(defaultOption);
    }
  }, [defaultValue, options]);

  const handleSelect = (option) => {
    setSelectedOption(option);
    setIsOpen(false);
    onSelect(option);
    setBorderRadius(borderRadius === "4px" ? "4px 4px 0 0" : "4px");
  };

  const handleDropDownClick = () => {
    setIsOpen((prev) => !prev);
    // Change border radius when the div is clicked if it is open
    if (!isOpen) {
      if (borderRadius === "4px") {
        setBorderRadius("4px 4px 0 0");
      }
    } else {
      if (borderRadius !== "4px") {
        setBorderRadius("4px");
      }
    }
  };

  return (
    <div
      className="dropdown"
      onMouseLeave={() => {
        if (isOpen) {
          setIsOpen(false);
          setBorderRadius(borderRadius === "4px" ? "4px 4px 0 0" : "4px");
        }
      }}
    >
      <div className="dropdown-control" style={{ borderRadius: borderRadius }}>
        <div className="dropdown-header" onClick={handleDropDownClick}>
          <span style={{ color: selectedOption ? "inherit" : "#818283" }}>
            {selectedOption ? selectedOption.label : label}
          </span>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="18"
            height="19"
            viewBox="0 0 18 19"
            fill="none"
            className={`dropdown-arrow ${isOpen ? "open" : ""}`}
          >
            <path
              d="M3.34314 7.00049L8.99999 12.6573L14.6568 7.00049"
              stroke="#77BC1F"
              strokeWidth="2"
              strokeLinecap="round"
            />
          </svg>
        </div>
        <div className={`dropdown-menu ${isOpen ? "open" : ""}`}>
          {options.map((option, index) => (
            <div
              key={index}
              className="dropdown-option"
              onClick={() => handleSelect(option)}
            >
              {option.label}
              {selectedOption?.value === option.value && (
                <span className="dropdown-marker" />
              )}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default Dropdown;
