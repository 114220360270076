import { useContext } from "react";
import { CurrentView } from "../../context/CurrentView.js";
import { RetailerSelection } from "../../context/RetailerSelection.js";

import DropDown from "../../components/DropDown/DropDown.js";

import "./LandingView.css";

function LandingView({ retailers, handleSubmit, isMobile }) {
  const { currentView, setCurrentView } = useContext(CurrentView);
  const { selectedRetailer, setSelectedRetailer, emailInput, setEmailInput } =
    useContext(RetailerSelection);

  if (currentView !== "LandingPage") {
    return;
  } else {
    return (
      <div className="landing-content">
        <div className="landing-text-container">
          <h1>Get instant product classifications</h1>
          <h3 style={{ color: "#818283", fontWeight: "200" }}>
            Your publicly available data. Our classification APIs.
          </h3>
        </div>
        <div className="landing-form">
          <DropDown
            label="Select retailer"
            options={retailers.map((retailer) => {
              return {
                label: retailer.name,
                value: retailer,
              };
            })}
            onSelect={(option) => setSelectedRetailer(option.value)}
          />
          <div className="email-input-container">
            <input
              className="email-input-field"
              placeholder="Enter your email"
              value={emailInput}
              onChange={(e) => setEmailInput(e.target.value)}
            ></input>
          </div>
        </div>
        <button
          className="cta"
          onClick={() => {
            if (emailInput !== "" && selectedRetailer !== null) {
              if (
                emailInput.includes("@") &&
                emailInput.includes(".") &&
                !emailInput.includes(" ") &&
                emailInput.length > 5
              ) {
                setCurrentView("LoadingPage");
                handleSubmit();
              }
            }
          }}
        >
          Classify now
        </button>
      </div>
    );
  }
}

export default LandingView;
