import { useContext } from "react";
import { ModalManagement } from "../../../context/ModalManagement.js";
import { RetailerSelection } from "../../../context/RetailerSelection.js";

import TableRow from "../../../components/TableRow/TableRow.js";

import "./ProductInfo.css";

function ProductInfo({ products, isMobile }) {
  const { setActiveProduct, setItemModalOpen, setPricingModalOpen } =
    useContext(ModalManagement);
  const { selectedRetailer } = useContext(RetailerSelection);

  function removeComma(str) {
    if (str !== null && str !== undefined) {
      if (Array.isArray(str)) {
        // convert array to string, seperate items with semicolon
        return str.join("; ");
      } else {
        // remove commas from string
        return str.replace(/,/g, "");
      }
    }
  }

  function downloadCSV(data, filename = "products.csv") {
    // const csvData = convertArrayOfObjectsToCSV(data);
    const header =
      "id,Name,Category,Brand,UPC,Product Type,Quantity,Weight,Ground(DOT),Ground Exceptions,Air(AITA),Air Exceptions,Sea(IMDG),Sea Exceptions,RCRA Codes,State Waste Codes,Confidence Score," +
      "\n,";
    let csvString = data.map((item) => {
      const stateCodes = item.state_codes.map((code, index) => {
        return item.state_codes_states[index] + ": " + code;
      });

      let row = "";
      row += removeComma(item.name) + ",";
      row += removeComma(item.category) + ",";
      row += removeComma(item.brand) + ",";
      row += removeComma(item.upc) + ",";
      row += removeComma(item.product_type) + ",";
      row += removeComma(item.quantity) + ",";
      row += removeComma(item.weight_str) + ",";
      row += removeComma(item.ground_regulated) + ",";
      row += removeComma(item.ground_regulated_exception) + ",";
      row += removeComma(item.air_regulated) + ",";
      row += removeComma(item.air_regulated_exception) + ",";
      row += removeComma(item.sea_regulated) + ",";
      row += removeComma(item.sea_regulated_exception) + ",";
      row += removeComma(item.rcra_codes) + ",";
      row += removeComma(stateCodes) + ",";
      row += removeComma(item.confidence_score) + ",";
      row += "\n";
      return row;
    });

    const csvData = header + csvString;

    const blob = new Blob([csvData], { type: "text/csv;charset=utf-8;" });
    const link = document.createElement("a");
    link.href = URL.createObjectURL(blob);
    link.download = filename;
    link.style.visibility = "hidden";

    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }

  return (
    <div className="items-table">
      {!isMobile && (
        <>
          <div className="table-header">
            <th style={{ width: "100%" }}>Product Name</th>
            <th style={{ width: "60%" }}>Category</th>
            <th style={{ width: "100%" }}>Brand</th>
            <th style={{ minWidth: 200 }}>
              <div className="classification-icons">
                <img
                  style={{ width: 20 }}
                  src={`${process.env.PUBLIC_URL}/truck-line.svg`}
                  alt="truck"
                />
                <img
                  style={{ width: 20 }}
                  src={`${process.env.PUBLIC_URL}/plane-line.svg`}
                  alt="plane"
                />
                <img
                  style={{ width: 20 }}
                  src={`${process.env.PUBLIC_URL}/ship-line.svg`}
                  alt="ship"
                />
                <img
                  style={{ width: 20 }}
                  src={`${process.env.PUBLIC_URL}/delete-bin-fill.svg`}
                  alt="trash"
                />
              </div>
            </th>
            <div style={{ minWidth: 180 }} />
          </div>
        </>
      )}
      <div className="table-body">
        {products &&
          products.slice(0, 10).map((item) => (
            <TableRow
              key={item.id}
              product={item}
              onView={() => {
                setActiveProduct(item);
                setItemModalOpen(true);
                window.dataLayer.push({
                  event: "view_classifications"
                });
              }}
              isMobile={isMobile}
            />
          ))}

        <div className="overlay-container">
          {products &&
            products.slice(0, 5).map((item) => (
              <TableRow
                key={item.id}
                product={item}
                skeleton={true}
                onView={() => {
                  setActiveProduct(item);
                  setItemModalOpen(true);
                  window.dataLayer.push({
                    event: "view_classifications"
                  });
                }}
                isMobile={isMobile}
              />
            ))}

          <div className="overlay">
            <img src={`${process.env.PUBLIC_URL}/lock-closed.svg`} alt="lock" />
            <h3>What do you think? Ready to see more?</h3>
            <div className="overlay-button-group">
              <button
                className="cta"
                onClick={() => {
                  setPricingModalOpen(true);
                  window.dataLayer.push({
                    event: 'view_pricing'
                  });
                }}
              >
                View pricing
              </button>
              <button
                className="button-outline"
                style={{ padding: "5px 32px", width: "auto" }}
                onClick={() => {
                  downloadCSV(
                    products,
                    selectedRetailer.name +
                      " Classifications by SmarterX AI.csv"
                  );
                  window.dataLayer.push({
                    "event": "download_results"
                  });
                }}
              >
                <span style={{ color: "#77BC1F", fontSize: "20px" }}>
                  Download 100 free
                </span>
              </button>
            </div>
          </div>
        </div>
      </div>

      {!isMobile && (
        <>
          <img
            className="ai-logo"
            src={`${process.env.PUBLIC_URL}/smarterx_logo_ai.svg`}
            alt="Logo"
          />
          <div className="highlight" />
        </>
      )}
    </div>
  );
}

export default ProductInfo;
