import React from "react";
import ClassificationBubble from "../ClassificationBubble/ClassificationBubble";
import "./ItemModal.css";

function ItemModal({ show, onClose, product }) {
  if (!show || !product) {
    return null;
  }

  const codesByState = {};

  product.state_codes.forEach((code, index) => {
    const state = product.state_codes_states[index];
    if (!codesByState[state]) {
      codesByState[state] = [];
    }
    codesByState[state].push(code);
  });

  const formattedOutput = Object.keys(codesByState)
    .map((state) => `${state}: ${codesByState[state].join(", ")}`)
    .join("\n");

  return (
    <div className="modal-overlay">
      <div className="modal-content">
        <div className="modal-header">
          <h4
            style={{
              fontWeight: "600",
              paddingLeft: 30,
              paddingRight: 30,
              maxWidth: "600px",
            }}
          >
            {product.name}
          </h4>
          <button onClick={onClose} className="close-button">
            Close
          </button>
        </div>
        <div className="modal-card">
          <div className="card-row">
            <div className="metric">
              <p style={{ fontSize: 16, fontWeight: "bold" }}>
                {product.brand}
              </p>
              <p style={{ color: "#77BC1F", fontSize: "14px" }}>
                {product.category}
              </p>
            </div>
            <div className="metric">
              <p className="item-label">UPC</p>
              <p className="item-text">{product.upc}</p>
            </div>
            <div className="metric">
              <p className="item-label">Product type</p>
              <p className="item-text">{product.product_type}</p>
            </div>
          </div>
          <div className="card-row">
            <div className="standout-metric">
              <div className="standout-background">
                <p className="standout-item-text">
                  {Math.round(product.confidence_score * 100) + "%"}
                </p>
                <p className="standout-item-label">Confidence Score</p>
              </div>
            </div>
            <div className="metric">
              <p className="item-label">Quantity</p>
              <p className="item-text">{product.quantity}</p>
            </div>
            <div className="metric">
              <p className="item-label">Weight</p>
              <p className="item-text">{product.weight_str}</p>
            </div>
          </div>
        </div>
        <div className="smarter-card">
          <p style={{ fontWeight: "bold", fontSize: "14px" }}>Shipping</p>
          <div className="transport-row">
            <div className="transport-container">
              <img
                style={{ width: 20, marginTop: 4 }}
                src={`${process.env.PUBLIC_URL}/truck-line.svg`}
                alt="truck"
              />
              <div className="transport-content">
                <p style={{ fontSize: "16px" }}>Ground (DOT)</p>
                <div
                  style={{
                    width: "100%",
                    height: 1,
                    backgroundColor: "#D9DADB",
                  }}
                />
                <p style={{ fontSize: "14px" }}>Least regulatory burden:</p>
                <ClassificationBubble
                  limited_quantites={product.ground_limited_quantities}
                  regulated={product.ground_regulated}
                  regulated_exception={product.ground_regulated_exception}
                />
                {product.ground_regulated_exception && (
                  <p style={{ textAlign: "left", fontSize: "14px" }}>
                    Exceptions: {product.ground_regulated_exception}
                  </p>
                )}
              </div>
            </div>
            <div className="transport-container">
              <img
                style={{ width: 20, marginTop: 4 }}
                src={`${process.env.PUBLIC_URL}/plane-line.svg`}
                alt="plane"
              />
              <div className="transport-content">
                <p style={{ fontSize: "16px" }}>Air (IATA)</p>
                <div
                  style={{
                    width: "100%",
                    height: 1,
                    backgroundColor: "#D9DADB",
                  }}
                />
                <p style={{ fontSize: "14px" }}>Least regulatory burden:</p>
                <ClassificationBubble
                  limited_quantites={product.air_limited_quantities}
                  regulated={product.air_regulated}
                  regulated_exception={product.air_regulated_exception}
                />
                {product.air_regulated_exception && (
                  <p style={{ textAlign: "left", fontSize: "14px" }}>
                    Exceptions: {product.air_regulated_exception}
                  </p>
                )}
              </div>
            </div>
            <div className="transport-container">
              <img
                style={{ width: 20, marginTop: 4 }}
                src={`${process.env.PUBLIC_URL}/ship-line.svg`}
                alt="ship"
              />
              <div className="transport-content">
                <p style={{ fontSize: "16px" }}>Sea (IMDG)</p>
                <div
                  style={{
                    width: "100%",
                    height: 1,
                    backgroundColor: "#D9DADB",
                  }}
                />
                <p style={{ fontSize: "14px" }}>Least regulatory burden:</p>
                <ClassificationBubble
                  limited_quantites={product.sea_limited_quantities}
                  regulated={product.sea_regulated}
                  regulated_exception={product.sea_regulated_exception}
                />
                {product.sea_regulated_exception && (
                  <p style={{ textAlign: "left", fontSize: "14px" }}>
                    Exceptions: {product.sea_regulated_exception}
                  </p>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="smarter-card">
          <p style={{ fontWeight: "bold", fontSize: "14px" }}>
            Waste & disposal
          </p>
          <div className="card-row">
            <div className="metric">
              <p className="item-label">RCRA codes</p>
              <p className="item-text">
                {product.rcra_codes.length > 0
                  ? product.rcra_codes.join(", ")
                  : "None"}
              </p>
            </div>
            <div className="metric">
              <p className="item-label">State codes</p>
              <div>
                {formattedOutput.split("\n").map((line, index) => (
                  <div key={index}>
                    <p className="item-text">{line}</p>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ItemModal;
